class EventService {
  static emit(name: string, payload: any = null) {
    const event = new CustomEvent(name, {
      detail: payload
    });
    document.dispatchEvent(event);
  }

  static on(eventType: string, callback: (detail: any) => void) {
    const handler = (event: Event) => {
      const customEvent = event as CustomEvent;
      callback(customEvent.detail);
    };
    document.addEventListener(eventType, handler);
  }

  static off(eventType: string, callback: (detail: any) => void) {
    const handler = (event: Event) => {
      const customEvent = event as CustomEvent;
      callback(customEvent.detail);
    };
    document.removeEventListener(eventType, handler);
  }
}

export default EventService;
